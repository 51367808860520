<template>
  <div>
    <b-card :title="profile.name">
      <b-card-text class="font-semibold" v-if="profile.profile">
        Usuario: {{ profile.username }}
      </b-card-text>

      <b-card-text class="font-semibold">
        Email: {{ profile.email }}
      </b-card-text>

      <b-card-text class="font-semibold" v-if="profile.profile">
        Perfil: {{ profile.profile.name }}
      </b-card-text>

      <b-button size="sm" variant="success" v-b-modal.modal-profile>
        Actualizar
      </b-button>
    </b-card>
    <profile-form
      :handlePostSubmit="handlePostSubmit"
      :record="profile"
    ></profile-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";
import ProfileForm from "./ProfileForm";

export default {
  inject: ["userRepository"],

  mixins: [customComponentActions],

  components: {
    ProfileForm
  },

  computed: {
    ...mapState("user", ["user"])
  },

  data() {
    return {
      user_Id: "",
      profile: {}
    };
  },

  methods: {
    ...mapActions("user", ["reloadUser"]),

    handlePostSubmit() {
      this.handleUser();
    },

    async handleUser() {
      const me = this;
      let result = await me.userRepository.get(me.user_id);

      me.profile = deserialize(result.data);
      this.$store.dispatch("user/reloadUser", me.profile);
    }
  },

  created() {
    const me = this;

    me.user_id = me.user.id;

    me.handleUser();
  }
};
</script>

<style lang="css" scoped></style>
